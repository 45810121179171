// src/components/Hero.js

import React from 'react';
import PropTypes from 'prop-types';

const Hero = ({ onSubscribe }) => {
  return (
    <section
      className="text-white text-center shadow-md py-14 md:py-20 lg:py-24"
      style={{
        background: 'linear-gradient(to right, rgba(5, 3, 49, 1), rgba(9, 5, 85, 1), rgba(6, 3, 62, 1), rgba(5, 3, 49, 1))',
      }}
    >
      <div className="max-w-screen-xl mx-auto px-6 sm:px-8 lg:px-12">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-[2.2rem] font-extrabold mb-8 md:mb-10 lg:mb-12 px-4">
          Never miss another <br /> of your favorite podcasts... <br />
        </h1>
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-[2.5rem] font-extrabold mb-3 md:mb-4 lg:mb-5 text-[#FFD700] px-4">
          Daily Highlights Straight to Your Inbox - <br /> <em>powered by AI.</em>
        </h2>
        <p className="text-lg sm:text-xl md:text-2xl lg:text-[1.4rem] mt-2 md:mt-3 lg:mt-3 mb-8 md:mb-10 text-gray-300 px-4">
          We monitor your favorite shows and deliver you the best, fresh insights.
        </p>
        <button
          onClick={onSubscribe}
          className="bg-[#6424e4] hover:bg-[#4F49C5] hover:shadow-lg hover:shadow-[#4F49C5]/50 text-white font-bold py-4 px-12 rounded-md transition-all duration-300 transform hover:scale-105 text-lg md:text-xl"
        >
          Sign up for $9.99 per month!
        </button>
      </div>
    </section>
  );
};

Hero.propTypes = {
  onSubscribe: PropTypes.func.isRequired,
};

export default Hero;
