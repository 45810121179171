import React from 'react';

function Cancel() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-50">
      <h1 className="text-3xl font-bold text-red-600">Payment Canceled</h1>
      <p className="mt-2 text-gray-600">It seems you canceled the payment. Feel free to try again.</p>
    </div>
  );
}

export default Cancel;
