// src/components/Success.js

import React from 'react';

function Success() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-50 p-4">
      <h1 className="text-3xl font-bold text-green-600">Payment Successful!</h1>
      <p className="mt-4 text-gray-600 text-center">
        Thank you for subscribing! Please fill out this form to start receiving our newsletter:
      </p>
      <a
        href="https://forms.gle/ZKHhTk6ph4srbDsA6"
        target="_blank"
        rel="noopener noreferrer"
        className="mt-6 px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
      >
        Fill Out the Form
      </a>
    </div>
  );
}

export default Success;
