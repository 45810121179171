// src/components/Insights.js

import React from 'react';

const Insights = () => {
  // Sample insights data
  const insights = [
    {
      title: "The Diary of a CEO",
      description: (
        <div className="text-lg mt-2 text-left">
          <strong>People with higher income have higher emotional intelligence (EQ), but a lower (not higher) IQ. </strong>Those in the top 1% income bracket have lower IQs than those in the top 10%.<a href="https://www.youtube.com/watch?v=P1ALkQMfkjc&t=602s" target="_blank" rel="noopener noreferrer" className="text-white underline ml-2">
            [Go to 6:22]
          </a>
        </div>
      ),
      thumbnail: "https://yt3.googleusercontent.com/xSwK9eN4KTkDzIA0Qfv6ng_QQ1JrI_GxGFRp_97TWPaO5euI6Z1gxjNUksfktPka6b26l_zx=s160-c-k-c0x00ffffff-no-rj",
    },
    {
      title: "Sharp Tech with Ben Thompson",
      description: (
        <div className="text-lg mt-2 text-left">
          <strong>OpenAI's success with ChatGPT has shifted it from a research-focused organization to a consumer tech company, straying from its original AGI plan.</strong> This change has led to internal friction and departures among early employees.
          <a href="https://youtu.be/R_A53Vb6r38?t=172" target="_blank" rel="noopener noreferrer" className="text-white underline ml-2">
            [Go to 2:52]
          </a>
        </div>
      ),
      thumbnail: "https://yt3.googleusercontent.com/_UT6p70kI3McL9NhS5g0pZcgZBuyM60qvbregjaOZc1bmLZnTY5GcbJVEjoRfThg3yBdyrr_2g=s160-c-k-c0x00ffffff-no-rj",
    },
    {
      title: "All-In Podcast",
      description: (
        <div className="text-lg mt-2 text-left">
          <strong>Government spending is 50% of US GDP, implying half of the population relies on government</strong>, potentially driving acceptance of socialist policies. 50% are employed directly by government or indirectly (government is primary revenue source of their business).
          <a href="https://youtu.be/pSFvOUswFwA?t=1428" target="_blank" rel="noopener noreferrer" className="text-white underline ml-2">[Go to 23:48]
          </a>
        </div>
      ),
      thumbnail: "https://yt3.googleusercontent.com/ytc/AIdro_muNFL-sKuOPm72UvG-ofixqx70KVyRS4365-fTtxH_cg=s160-c-k-c0x00ffffff-no-rj",
    },
  ];

  return (
    <section className="bg-custom-gradient py-10 text-white shadow-md">
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold">Insights from Our Newsletter</h2>
      </div>

      <div className="flex flex-col items-center gap-8">
        {insights.map((insight, index) => (
          <div key={index} className="flex bg-gray-700 bg-opacity-50 border border-gray-600 rounded-lg shadow-md p-5 w-11/12 max-w-2xl">
            <img
              src={insight.thumbnail}
              alt="Podcast Thumbnail"
              className="w-24 h-24 rounded-full object-cover mr-6"
            />
            <div className="flex flex-col items-start text-left">
              <h3 className="text-xl text-gray-200 font-bold mb-2">{insight.title}</h3>
              <p className="text-lg">{insight.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Insights;
