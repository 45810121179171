// src/components/Footer.js

import React from 'react';

const Footer = () => {
  return (
    <footer
      className="relative text-white text-center py-6 px-4"
      style={{
        background: 'linear-gradient(to right, rgb(9, 4, 21), rgba(9, 4, 21), rgb(9, 4, 21), rgba(9, 4, 21))',
      }}
    >
      <div
        className="absolute top-0 left-0 w-full h-1"
        style={{
          background: 'linear-gradient(to right, #7146ff00, rgba(113, 70, 255, 1), rgba(113, 70, 255, 0))',
        }}
      ></div>

      <div className="mb-6">
        <div className="flex justify-center gap-4 mb-4">
          <a href="mailto:diego@aipodcastinsights.com" className="text-white text-base hover:underline">Contact</a>
          <a href="/privacy" className="text-white text-base hover:underline">Privacy Policy</a>
          <a href="/terms" className="text-white text-base hover:underline">Terms of Service</a>
        </div>

        <div className="mt-6 text-sm">
          <p>
            &copy; <strong>{new Date().getFullYear()} AI Podcast Insights. All rights reserved.</strong>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
