// src/components/Testimonials.js

import React from 'react';

const testimonialsData = [
    {
        quote: (
            <>
                I hated to miss some of my favorite podcasts. Now I can <strong>choose</strong> if it's worth listening to one or get some <strong>quick insights on the spot</strong>.
            </>
        ),
        name: "Katie from USA"
    },
    {
        quote: (
            <>
                It was <strong>impossible</strong> to listen to all the podcasts that I love. Now I can easily <strong>stay on top</strong> of what's happening AND <strong>learn</strong> their key takeaways.
            </>
        ),
        name: "Gi from Brasil"
    },
    {
        quote: (
            <>
                Perfect for <strong>staying updated on the latest in tech and AI</strong> without the time investment. <strong>Highly recommend!</strong>
            </>
        ),
        name: "Santiago from Spain"
    },
    {
        quote: (
            <>
                Among all the noise, it delivers some <strong>quick fresh takes from the podcasts I love</strong>.
            </>
        ),
        name: "Mike from England"
    },
];

const Testimonials = () => {
    return (
        <section
            className="text-white text-center py-12 sm:py-16"
            style={{
                background: 'linear-gradient(to right, rgba(0, 3, 49, 1), rgba(7, 16, 68, 1))',
            }}
        >
            <div className="max-w-screen-lg mx-auto px-4 sm:px-8">
                <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-8 sm:mb-10">
                    What Our Users Say
                </h2>
                <div className="flex flex-col items-center space-y-6 sm:space-y-8">
                    {testimonialsData.map((testimonial, index) => (
                        <blockquote
                            key={index}
                            className="bg-white/10 text-gray-100 italic rounded-lg shadow-md p-4 sm:p-6 max-w-md sm:max-w-2xl"
                        >
                            <p className="text-base sm:text-lg mb-2 sm:mb-4">“{testimonial.quote}”</p>
                            <cite className="block text-sm sm:text-base text-gray-400">
                                – {testimonial.name}
                            </cite>
                        </blockquote>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
