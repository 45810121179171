// src/components/Paywall.js

import React, { useState, useRef, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';

// Initialize Stripe with your public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Paywall = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const modalRef = useRef(null);

  const handleCheckout = async () => {
    setIsLoading(true);
    const stripe = await stripePromise;

    try {
      // Call your backend to create the checkout session
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ priceId: 'price_1QNFKbCtFykeQb8QQCJthZr2' }), // Replace with your actual Price ID
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { sessionId } = await response.json();

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe Checkout Error:', error);
        alert(error.message);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('There was an issue processing your request. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Close modal on Esc key press
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    // Attach the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Focus on the modal when it opens
    if (modalRef.current) {
      modalRef.current.focus();
    }

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <div
      className="focus:outline-none"
      tabIndex="-1"
      ref={modalRef}
    >
      <h2 id="paywall-heading" className="text-2xl text-gray-700 font-extrabold mb-4 text-center">
        What you get for $9.99 per month
      </h2>
      <p className="text-gray-700 mb-6 text-left">
        <b>Daily news and insights for the podcasts, youtube channels, and interests you subscribe to.</b> <br/><br/>
        <b>Each email contains three sections:</b><br/>
            • 🏃‍♂️ <b>Quick Insights</b>: top insights for each of your shows<br/>
            • 🔍 <b>Insights You Might Like</b>: other insights from podcasts related to your interests<br/>
            • 📄 <b>Detailed Insights</b>: ~5 insights per each of your shows<br/><br/>
      </p>
      <button
        onClick={handleCheckout}
        disabled={isLoading}
        className={`w-full bg-[#6424e4] hover:bg-[#4F49C5] text-white font-bold py-3 px-6 rounded-md transition-all duration-300 ${
          isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg hover:shadow-[#4F49C5]/50'
        }`}
      >
        {isLoading ? 'Processing...' : 'Subscribe Now'}
      </button>
    </div>
  );
};

Paywall.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Paywall;
