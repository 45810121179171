// src/components/Header.js

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation

const Header = () => {
  return (
    <header
      className="relative text-white shadow-md"
      style={{
        background: 'linear-gradient(to right, rgba(5, 3, 49, 1), rgba(9, 5, 85, 1), rgba(6, 3, 62, 1), rgba(5, 3, 49, 1))',
        height: '80px', // Keeping height as needed
        padding: '0 20px', // Adding horizontal padding
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div className="max-w-screen-xl mx-auto w-full flex justify-between items-center relative">
        <div className="flex items-center space-x-2">
          <span className="text-3xl lg:text-4xl">🎙️</span>
          <Link to="/" className="text-xl sm:text-2xl lg:text-3xl font-extrabold text-white no-underline">
            AI Podcast Insights
          </Link>
        </div>
        <nav className="flex">
          <a href="https://forms.gle/tkZyErb78DgYsUi17" className="ml-2 md:ml-4 text-sm sm:text-base lg:text-lg font-medium text-white transition-colors duration-300 hover:text-yellow-400">
            Subscribe to the newsletter
          </a>
        </nav>
      </div>
      {/* Adding the gradient line after the header content */}
      <div
        className="absolute left-0 bottom-0 w-full h-1"
        style={{
          background: 'linear-gradient(to right, #7146ff00, rgba(113, 70, 255, 1), rgba(113, 70, 255, 0))',
        }}
      ></div>
    </header>
  );
};

export default Header;
