import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Header from './components/Header';
import Hero from './components/Hero';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import Insights from './components/Insights';
import CTA from './components/CTA'; 
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import HowItWorks from './components/HowItWorks';
import Paywall from './components/Paywall';
import Modal from './components/Modal';
import Success from './components/Success';
import Cancel from './components/Cancel';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

function App() {
  const [isPaywallOpen, setIsPaywallOpen] = useState(false);

  const handleOpenPaywall = () => {
    setIsPaywallOpen(true);
  };

  const handleClosePaywall = () => {
    setIsPaywallOpen(false);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <Routes>
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/" element={
            <>
              <Hero onSubscribe={handleOpenPaywall} />
              <HowItWorks />
              <Insights />
              <Testimonials />
              <CTA />
              <Footer />
            </>
          } />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          {/* Add other routes as needed */}
        </Routes>
        <Modal isOpen={isPaywallOpen} onClose={handleClosePaywall}>
          <Paywall onClose={handleClosePaywall} />
        </Modal>
      </div>
    </Router>
  );
}

export default App;
