// src/components/TermsOfService.js

import React from 'react';

const TermsOfService = () => {
    return (
        <div
            className="w-full min-h-screen text-white px-6 md:px-10 py-16"
            style={{
                background: 'linear-gradient(to right, rgba(0, 3, 49, 1), rgba(7, 16, 68, 1))',
            }}
        >
            <div className="max-w-3xl mx-auto px-4 md:px-8">
                <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
                <p className="text-sm mb-4">
                    <strong>Effective Date:</strong> September 2nd, 2024
                </p>

                <p className="mb-8 leading-relaxed">
                    Welcome to AI Podcast Insights ("we," "our," "us"). These Terms of Service govern your access to and use of our services, including our website and any related content or services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree, do not use our Services.
                </p>

                <h2 className="text-3xl font-semibold mb-4">1. Acceptance of Terms</h2>
                <p className="mb-8 leading-relaxed">
                    By using our Services, you represent that you have read, understood, and agree to these Terms, and that you are at least 18 years old or are using the Services under the supervision of a parent or guardian.
                </p>

                <h2 className="text-3xl font-semibold mb-4">2. Changes to Terms</h2>
                <p className="mb-8 leading-relaxed">
                    We may modify these Terms at any time. We will notify you of significant changes by posting a notice on our website or by other means. Your continued use of the Services after any changes signifies your acceptance of the new Terms.
                </p>

                <h2 className="text-3xl font-semibold mb-4">3. Privacy Policy</h2>
                <p className="mb-8 leading-relaxed">
                    Your use of our Services is also governed by our <a href="/privacy" className="text-blue-400 hover:text-blue-300" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. By using our Services, you consent to our collection and use of your information as outlined in the Privacy Policy.
                </p>

                <h2 className="text-3xl font-semibold mb-4">4. User Accounts</h2>
                <p className="mb-8 leading-relaxed">
                    To access certain features of our Services, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
                </p>

                <h2 className="text-3xl font-semibold mb-4">5. Use of Services</h2>
                <p className="mb-4 leading-relaxed">
                    You agree to use our Services only for lawful purposes and in accordance with these Terms. You agree not to:
                </p>
                <ul className="list-disc list-inside mb-8 leading-relaxed">
                    <li>Use the Services in any way that violates any applicable federal, state, local, or international law or regulation.</li>
                    <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the Services.</li>
                    <li>Transmit any advertising or promotional material without our prior written consent.</li>
                </ul>

                <h2 className="text-3xl font-semibold mb-4">6. Intellectual Property Rights</h2>
                <p className="mb-8 leading-relaxed">
                    All content and materials available through our Services, including text, graphics, logos, and software, are the property of AI Podcast Insights or our licensors and are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, or create derivative works from any content without our express written permission.
                </p>

                <h2 className="text-3xl font-semibold mb-4">7. Third-Party Links</h2>
                <p className="mb-8 leading-relaxed">
                    Our Services may contain links to third-party websites or services. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. We encourage you to read the terms and conditions and privacy policies of any third-party websites you visit.
                </p>

                <h2 className="text-3xl font-semibold mb-4">8. Disclaimers</h2>
                <p className="mb-8 leading-relaxed">
                    Our Services are provided on an "as is" and "as available" basis. We do not warrant that the Services will be uninterrupted or error-free, that defects will be corrected, or that the Services are free of viruses or other harmful components. We disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose.
                </p>

                <h2 className="text-3xl font-semibold mb-4">9. Limitation of Liability</h2>
                <p className="mb-8 leading-relaxed">
                    To the fullest extent permitted by law, in no event shall AI Podcast Insights, its affiliates, or their licensors, service providers, employees, agents, officers, or directors be liable for any indirect, incidental, special, consequential, or punitive damages, including, without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use of, or inability to use, our Services.
                </p>

                <h2 className="text-3xl font-semibold mb-4">10. Governing Law</h2>
                <p className="mb-8 leading-relaxed">
                    These Terms shall be governed by and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions. Any disputes arising out of or related to these Terms or your use of the Services shall be resolved exclusively in the courts located in [Your City, State/Country].
                </p>

                <h2 className="text-3xl font-semibold mb-4">11. Contact Information</h2>
                <p className="mb-2 leading-relaxed">
                    If you have any questions or concerns about these Terms, please contact us at:
                </p>
                <p className="text-lg">
                    <strong>AI Podcast Insights</strong><br />
                    <a href="mailto:diego@aipodcastinsights.com" className="text-blue-400 hover:text-blue-300">diego@aipodcastinsights.com</a>
                </p>
            </div>
        </div>
    );
};

export default TermsOfService;
