// src/components/PrivacyPolicy.js

import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div
            className="w-full px-6 md:px-10 py-16 text-white"
            style={{
                background: 'linear-gradient(to right, rgba(0, 3, 49, 1), rgba(7, 16, 68, 1))',
            }}
        >
            <div className="max-w-3xl mx-auto">
                <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
                <p className="text-sm mb-4">
                    <strong>Effective Date:</strong> September 2nd, 2024
                </p>

                <p className="mb-8 leading-relaxed">
                    AI Podcast Insights ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you access our website and any related services (collectively, the "Services"). By using our Services, you agree to the collection and use of information in accordance with this policy.
                </p>

                <h2 className="text-3xl font-semibold mb-4">1. Information We Collect</h2>
                <p className="mb-4 leading-relaxed">We may collect the following types of information:</p>

                <h3 className="text-2xl font-medium mb-2">a. Personal Information</h3>
                <p className="mb-8 leading-relaxed">Contact Information: Name, email address, phone number, and any other information you provide when you sign up for our newsletter or contact us.</p>

                <h3 className="text-2xl font-medium mb-2">b. Usage Data</h3>
                <p className="mb-8 leading-relaxed">Information about how you access and use our Services, including your IP address, browser type, pages visited, and time spent on the website.</p>

                <h3 className="text-2xl font-medium mb-2">c. Cookies and Tracking Technologies</h3>
                <p className="mb-8 leading-relaxed">We use cookies and similar tracking technologies to track the activity on our Services and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

                <h2 className="text-3xl font-semibold mb-4">2. How We Use Your Information</h2>
                <p className="mb-4 leading-relaxed">We may use the information we collect for the following purposes:</p>
                <ul className="list-disc list-inside mb-8 leading-relaxed">
                    <li>To Provide Our Services: To deliver the insights and services you request.</li>
                    <li>To Communicate with You: To send you updates, newsletters, and other information related to your use of our Services.</li>
                    <li>To Improve Our Services: To monitor usage, analyze trends, and enhance user experience.</li>
                    <li>To Comply with Legal Obligations: To fulfill any legal requirements or obligations.</li>
                </ul>

                <h2 className="text-3xl font-semibold mb-4">3. Information Sharing and Disclosure</h2>
                <p className="mb-4 leading-relaxed">We do not sell or rent your personal information to third parties. We may share your information in the following situations:</p>
                <ul className="list-disc list-inside mb-8 leading-relaxed">
                    <li>With Service Providers: We may employ third-party companies and individuals to facilitate our Services, provide the Services on our behalf, or assist us in analyzing how our Services are used.</li>
                    <li>For Legal Reasons: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
                </ul>

                <h2 className="text-3xl font-semibold mb-4">4. Data Security</h2>
                <p className="mb-8 leading-relaxed">We take the security of your information seriously and use reasonable measures to protect it. However, no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.</p>

                <h2 className="text-3xl font-semibold mb-4">5. Your Rights</h2>
                <p className="mb-4 leading-relaxed">Depending on your location, you may have certain rights regarding your personal information, including:</p>
                <ul className="list-disc list-inside mb-8 leading-relaxed">
                    <li>The right to access, update, or delete the information we have on you.</li>
                    <li>The right to request a copy of your personal data.</li>
                    <li>The right to withdraw consent at any time where we rely on your consent to process your personal information.</li>
                </ul>

                <h2 className="text-3xl font-semibold mb-4">6. Third-Party Links</h2>
                <p className="mb-8 leading-relaxed">Our Services may contain links to third-party websites. We are not responsible for the content or privacy practices of these third-party sites. We encourage you to read the privacy policy of any site you visit.</p>

                <h2 className="text-3xl font-semibold mb-4">7. Children's Privacy</h2>
                <p className="mb-8 leading-relaxed">Our Services are not intended for children under the age of 13. We do not knowingly collect personally identifiable information from children. If we become aware that we have collected personal data from a child under age 13 without verification of parental consent, we will take steps to remove that information from our servers.</p>

                <h2 className="text-3xl font-semibold mb-4">8. Changes to This Privacy Policy</h2>
                <p className="mb-8 leading-relaxed">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page with a new effective date. You are advised to review this Privacy Policy periodically for any changes.</p>

                <h2 className="text-3xl font-semibold mb-4">9. Contact Us</h2>
                <p className="mb-4 leading-relaxed">If you have any questions about this Privacy Policy, please contact us:</p>
                <p className="text-lg">
                    <strong>AI Podcast Insights</strong><br />
                    <a href="mailto:diego@aipodcastinsights.com" className="text-blue-400 hover:text-blue-300">diego@aipodcastinsights.com</a>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
